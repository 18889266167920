import React from "react";
import {connect} from "react-redux";
import Card from "../../basics/card/Card";
import moment from "moment";
import {sortDateStrings} from "../../../utils/SortUtils";
import TableGrid from "../../basics/table-grid/TableGrid";
import {EventTypes} from "../../../constans/eventTypes";
import animalsDB from "../../../database/animalsDB";
import {withTranslation} from "react-i18next";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {isMobile} from "../../../utils/MobileUtils";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";

class TransferGrid extends React.Component {

    dateValueFormatter = value => {
        return value ? moment(value).format("DD.MM.YY") : "";
    };

    locationValueFormatter = value => {
        let loc = animalsDB.getAnimalLocationsByPlcmntID(value);
        return loc.length > 0 ? loc[0].name : "";
    };

    employeesValueFormatter = value => {
        if (value === "System") return "Fetura Cloud";
        const {employees} = this.props;
        let user = employees.find(e => e.LocalUserID === value);
        return user ? user.userName : value ? value : "";
    };

    getTransfers = value => value.filter(e => [EventTypes.TRANSFER].includes(e.EvCode));

    render() {
        const {t, events} = this.props;
        const _events = this.getTransfers(events.length ? events : []);
        const headers = [
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
                _mobileDate: true
            },
            {
                name: t("amount"),
                field: "AnmCnt",
                _mobileHide: true,
            },
            isMobile() ? {
                name: t("from"),
                field: "EvData.SrcID",
                valueFormatter: this.locationValueFormatter
            } : null,
            {
                name: t("to"),
                field: "EvData.DstID",
                valueFormatter: this.locationValueFormatter,
                colWidth: 4,
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 2,
            }
        ].filter(o=>!!o);
        return (
            <Card>
                <div className="d-flex justify-content-between">
                    <h5>{t("animalDocuments.transfers")}&nbsp;</h5>
                </div>
                <div>
                    <TableGrid data={_events.sort((a, b) => b.EvTime - a.EvTime)} headers={headers}
                               mobileRow={<DefaultMobileRow/>} showPagination={true} paginationItems={10}
                               scrollOnPageChange={false}/>
                </div>
            </Card>
        );
    }

}

TransferGrid = connect((state) => ({
    employees: state.user.employees,
    events: state.animalDocuments.events,
}))(TransferGrid);

export default withTranslation()(TransferGrid);
