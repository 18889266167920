import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {isEqual, sumBy} from "lodash";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";

export default class MommySeparationsRenderer extends React.Component {

    weightValueFormatter = (value) => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1});
    };

    render() {
        const {cycle: {separationsMommy, key}, expanded: {rows}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let rowValue = rows.find(o => o === key);
        let separation = separationsMommy[0];

        if (!separation) return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none"/>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent}/>
                <CycleRenderer className="print-display-none"/>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none"/>
                }
                <CycleRenderer className="normal-display-none"/>
                <CycleRenderer className="normal-display-none"/>
            </>
        );

        const operators = separationsMommy[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {
                            isEqual(key, rowValue) ? separationsMommy.map((separation, index) =>
                                <div key={index}>
                                    {separation.date}
                                </div>
                            ) : separation.date
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                    {
                        isAdvanced && isEqual(key, rowValue) ?
                                <div>
                                    {
                                        separationsMommy.map((separation, index) =>
                                            <div key={index}>
                                                {separation.amount}
                                            </div>
                                        )
                                    }
                                </div>
                            : <div>{sumBy(separationsMommy, 'amount')}</div>
                    }
                </CycleRenderer>
                <CycleRenderer className="print-display-none">
                    {
                        isAdvanced && isEqual(key, rowValue) ? separationsMommy.map((separation, index) =>
                            <div key={index}>
                                {this.weightValueFormatter(separation.weight)}
                            </div>
                        ) : this.weightValueFormatter(sumBy(separationsMommy, 'weight'))
                    }
                </CycleRenderer>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none">
                        {
                            separationsMommy.map((separation, index) =>
                                <div key={index}>
                                    {separation.date}
                                </div>
                            )
                        }
                    </CycleRenderer>
                }

                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                        separationsMommy.map((separation, index) =>
                            <div key={index}>
                                {separation.amount}
                            </div>
                        ) : sumBy(separationsMommy, 'amount')
                    }
                </CycleRenderer>
                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                        separationsMommy.map((separation, index) =>
                            <div key={index}>
                                {this.weightValueFormatter(separation.weight)}
                            </div>
                        ) : this.weightValueFormatter(sumBy(separationsMommy, 'weight'))
                    }
                </CycleRenderer>
            </>
        );
    }

}

MommySeparationsRenderer.propTypes = {
    cycle: PropTypes.shape({
        separationsMommy: PropTypes.array.isRequired
    }).isRequired
};
