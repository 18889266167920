import React from "react";
import PropTypes from "prop-types"

export default class CycleGridHeader extends React.Component {

    render() {
        const {title, columns, className} = this.props;
        return (
            <>
                <div
                    className={columns ? `cycle-grid-header has-children ${className}` : `cycle-grid-header ${className}`}
                    style={{gridColumn: columns ? `span ${columns.length}` : ""}}>
                    {title}
                </div>
                {
                    columns && columns.map((col, index) =>
                        <div className={`cycle-grid-header ${className} ${col.className}`} key={index}
                             style={{gridRow: 2}}>
                            {col.name}
                        </div>
                    )
                }
            </>
        );
    }

}

CycleGridHeader.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string
};