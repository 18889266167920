import React from "react";
import Tooltip from "../../basics/tooltip/Tooltip";
import {checkIfUserIsFarmAdmin, checkIfUserIsOwner, checkIfUserIsService} from "../../../utils/NewRolesUtils";

export default class CycleRenderer extends React.Component {

    getOperators = (operators, isAdvanced) => {
        if (Array.isArray(operators)) return isAdvanced ? [...new Set(operators)].join("\n") : operators[0];
        return "";
    };

    render() {
        const {children, className, comment, operators, isAdvanced, type} = this.props;
        const _operators = checkIfUserIsService() || checkIfUserIsFarmAdmin() || checkIfUserIsOwner() ? this.getOperators(operators, isAdvanced) : "";
        return (
            <div className={className ? "cycle-grid-content " + className : "cycle-grid-content"}>
                {children}
                {
                    comment &&
                    <Tooltip tooltipContent={comment} type={type || "error"}>
                        <div className={`box ${type || "error"}`}/>
                    </Tooltip>
                }
                {
                    _operators &&
                    <Tooltip tooltipContent={_operators} type={type || "info"}>
                        <div className={`box ${type || "info"}`}/>
                    </Tooltip>
                }
            </div>
        );
    }

}
