import React from "react";
import "./css/_basic-info-box.scss";
import {connect} from "react-redux";
import Card from "../basics/card/Card";
import {Col, Row} from "react-bootstrap";
import animalsDB from "../../database/animalsDB";
import moment from "moment";
import _ from "lodash";
import {EventTypes} from "../../constans/eventTypes";
import {withTranslation} from "react-i18next";

class BasicInfoGroupBox extends React.Component {
    constructor(props) {
        super(props);

        const animals = [];
        const {group} = props;

        [...group.AnmIDs, ...group.Rmvd].forEach(id => {
            let animal = animalsDB.getAnimalById(id);
            if (animal) animals.push(animal);
        });

        this.state = {
            animalsInGroup: animals
        }
    }

    getLastData() {
        const {animalsInGroup} = this.state;
        if (animalsInGroup.length) {
            let birthTime = 0;
            let insertionTime = 0;
            let deathTime = 0;
            animalsInGroup.forEach(animal => {
                if (+animal.DtaBrthTime > birthTime) birthTime = +animal.DtaBrthTime;
                if (+animal.DtaInTime > insertionTime) insertionTime = +animal.DtaInTime;
                if (+animal.DtaDthTime > deathTime) deathTime = +animal.DtaDthTime;
            });
            return {birthTime: birthTime, insertionTime: insertionTime, deathTime: deathTime}
        }
        return {};
    }

    getSupplier() {
        try {
            const {animalsInGroup} = this.state;
            let supplier = this.props.suppliers.find(item => item.ID === animalsInGroup[0].SupplierID);
            return supplier.Value;
        } catch (e) {
            return "-";
        }
    }

    getLocation() {
        const {group: {AnmIDs}} = this.props;
        if (AnmIDs.length) {
            let animal;
            AnmIDs.find(item => {
                const anm = animalsDB.getAnimalById(item);
                animal = _.cloneDeep(anm);
            });
            if (animal) {
                let locations = animalsDB.getAnimalLocationsByPlcmntID(animal.PlcmntID);
                let texts = locations.map(location => location.name);
                return texts.join(', ');
            }
        }
        return '-';
    }

    getDeathReason(lastDeathTime = 0) {
        let type = -1;
        const {groupEvents} = this.props;
        const {animalsInGroup} = this.state;
        const animal = animalsInGroup.filter(anm => anm.DtaDthTime === lastDeathTime)[0];
        if (animal) {
            let filtered = _.cloneDeep(groupEvents);
            filtered = filtered ? filtered : [];
            type = animal.DthRsn;
            filtered.sort((b, a) => (a.EvTime ? +a.EvTime : 0) - (b.EvTime ? +b.EvTime : 0));
            filtered = filtered.filter(event => event.EvCode === (type ? EventTypes.SELL : EventTypes.FALL))[0];
            if (filtered) {
                if (filtered.EvCode === EventTypes.SELL && filtered.EvData) {
                    let tmp = this.props.clients.filter(client => `${client.ID}` === `${filtered.EvData.Buyer}`)[0];
                    if (tmp) return {type: type, value: tmp.Value};
                }
                if (filtered.EvCode === EventTypes.FALL && filtered.EvData) {
                    let tmp = this.props.fallReasons.filter(reason => `${reason.ID}` === `${filtered.EvData.Reasn}`)[0];
                    if (tmp) return {type: type, value: tmp.Value};
                }
            }
        }
        return {type: type, value: '-'};
    }

    render() {
        const {group, t} = this.props;
        const {birthTime, insertionTime, deathTime} = this.getLastData();
        const deathReason = this.getDeathReason(deathTime);
        return (
            <Card className="basic-info-box">
                <div className="d-flex justify-content-between">
                    <h5>{t("animalDocuments.basicInformation")}</h5>
                </div>
                <div id="info-box">
                    <Row>
                        <Col md={6}>
                            <div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("groupNumber")}
                                    </div>
                                    <div className="info-column">
                                        {(group.GrNo1 ? group.GrNo1 : '-')}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("animalDocuments.lastInsertionDate")}
                                    </div>
                                    <div className="info-column">
                                        {insertionTime ? moment(insertionTime).format('DD.MM.YYYY') : '-'}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("supplier")}
                                    </div>
                                    <div className="info-column">
                                        {this.getSupplier()}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("placement")}
                                    </div>
                                    <div className="info-column">
                                        {this.getLocation()}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("animalDocuments.lastBirthDate")}
                                    </div>
                                    <div className="info-column">
                                        {birthTime ? moment(birthTime).format('DD.MM.YYYY') : '-'}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {deathReason.type === 1 ? t("animalDocuments.lastSellDate") : t("animalDocuments.lastFallDate")}
                                    </div>
                                    <div className="info-column">
                                        {deathTime ? moment(deathTime).format('DD.MM.YYYY') : '-'}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {deathReason.type === 1 ? t("animalDocuments.soldTo") : t("animalDocuments.deathReason")}
                                    </div>
                                    <div className="info-column">
                                        {deathReason.value}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }

}

BasicInfoGroupBox = connect(state => ({
    farm: state.location.farm,
    group: state.animalDocuments.selectedItem,
    groupEvents: state.animalDocuments.events,
    races: state.dictionary.race.WData[state.language.locale],
    suppliers: state.dictionary.suppliers.WData[state.language.locale],
    fallReasons: state.dictionary.fallReasons.WData[state.language.locale],
    clients: state.dictionary.clients.WData[state.language.locale]
}))(BasicInfoGroupBox);

export default withTranslation()(BasicInfoGroupBox);