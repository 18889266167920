import React from "react";
import PropTypes from "prop-types"
import CycleRenderer from "./CycleRenderer";
import {isEqual} from "lodash";
import * as usgStates from "validators-schema/Api/constants/usgStates";

export default class USGRenderer extends React.Component {


    render() {
        const {cycle: {usgs, key}, expanded: {rows}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let rowValue = rows.find(o => o === key);
        let lastUSG = usgs[usgs.length - 1];
        if (!lastUSG) {
            return (
                <>
                    {isAdvanced &&
                    <CycleRenderer className="print-display-none"/>}
                    <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent}/>
                    {isAdvanced && <CycleRenderer className="normal-display-none"/>}
                    <CycleRenderer className="normal-display-none"/>
                </>
            )
        }

        const operators = usgs[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {
                            isAdvanced && isEqual(key, rowValue) ?
                                <div>
                                    {
                                        usgs.map((usg, index) =>
                                            <div key={index}>
                                                {usg.date}
                                            </div>
                                        )
                                    }
                                </div> : <div>
                                    {!!lastUSG && lastUSG.date}
                                </div>
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none" comment={tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                    {
                        isAdvanced && isEqual(key, rowValue) ?
                            <div>
                                {
                                    usgs.map((usg, index) =>
                                        <div key={index}>
                                            {
                                                usg && (usg.result === true || usg.result === usgStates.USG_STATE.POSITIVE) &&
                                                <i className="fas fa-fw fa-check"/>
                                            }
                                            {
                                                usg && (usg.result === false || usg.result === usgStates.USG_STATE.NEGATIVE) &&
                                                <i className="fas fa-fw fa-times"/>
                                            }
                                            {
                                                usg && usg.result === usgStates.USG_STATE.REPEAT &&
                                                <i className="fas fa-fw fa-redo-alt"/>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            : <div>
                                {
                                    lastUSG && (lastUSG.result === true || lastUSG.result === usgStates.USG_STATE.POSITIVE) &&
                                    <i className="fas fa-fw fa-check"/>
                                }
                                {
                                    lastUSG && (lastUSG.result === false || lastUSG.result === usgStates.USG_STATE.NEGATIVE) &&
                                    <i className="fas fa-fw fa-times"/>
                                }
                                {
                                    lastUSG && (lastUSG.result === usgStates.USG_STATE.REPEAT) &&
                                    <i className="fas fa-fw fa-redo-alt"/>
                                }
                            </div>

                    }
                </CycleRenderer>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none">
                        {
                            isAdvanced ?
                                usgs.map((usg, index) =>
                                    <div key={index}>
                                        {usg.date}
                                    </div>
                                ) : lastUSG.date
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                            usgs.map((usg, index) =>
                                <div key={index}>
                                    {
                                        usg && (usg.result === true || usg.result === usgStates.USG_STATE.POSITIVE) &&
                                        <i className="fas fa-fw fa-check"/>
                                    }
                                    {
                                        usg && (usg.result === false || usg.result === usgStates.USG_STATE.NEGATIVE) &&
                                        <i className="fas fa-fw fa-times"/>
                                    }
                                    {
                                        usg && usg.result === usgStates.USG_STATE.REPEAT &&
                                        <i className="fas fa-fw fa-redo-alt"/>
                                    }
                                </div>
                            ) : <div>
                                {
                                    lastUSG && (lastUSG.result === true || lastUSG.result === usgStates.USG_STATE.POSITIVE) &&
                                    <i className="fas fa-fw fa-check"/>
                                }
                                {
                                    lastUSG && (lastUSG.result === false || lastUSG.result === usgStates.USG_STATE.NEGATIVE) &&
                                    <i className="fas fa-fw fa-times"/>
                                }
                                {
                                    lastUSG && (lastUSG.result === usgStates.USG_STATE.REPEAT) &&
                                    <i className="fas fa-fw fa-redo-alt"/>
                                }
                            </div>
                    }
                </CycleRenderer>
            </>
        );
    }
}

USGRenderer.propTypes = {
    cycle: PropTypes.shape({
        usgs: PropTypes.array.isRequired
    }).isRequired
};
