import React from "react";
import {connect} from "react-redux";
import Card from "../../basics/card/Card";
import moment from "moment";
import {sortDateStrings} from "../../../utils/SortUtils";
import TableGrid from "../../basics/table-grid/TableGrid";
import {EventTypes} from "../../../constans/eventTypes";
import {AnimalTypes} from "../../../constans/animalTypes";
import {medicineTypes} from "../../../constans/medicine";
import {withTranslation} from "react-i18next";
import {get, isEqual} from "lodash";
import DateHeaderComponent from "./DateHeaderComponent";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import PropTypes from "prop-types";
import {
    getAnimalEventsWithType,
    getCycles,
    getSelectedAnimalForDocuments
} from "../../../selectors/animalDocumentsSelectors";
import {compose} from "redux";
import {getGraftingProgram} from "../../../selectors/dictionarySelectors";

function mapStateToProps(state) {
    return {
        events: [...getAnimalEventsWithType(state, EventTypes.GRAFTING), ...getAnimalEventsWithType(state, EventTypes.TREATMENT)],
        cycles: getCycles(state).sort((a, b) => b.cycle - a.cycle),
        medicines: state.dictionary.medicine,
        employees: state.user.employees,
        graftingReasons: state.dictionary.graftingReason.WData[state.language.locale],
        graftingProgram: getGraftingProgram(state, state.animalDocuments.selectedItem.AnimalKind),
        animal: getSelectedAnimalForDocuments(state)
    };
}

class GraftingProgramGridCard extends React.Component {

    state = this.getData(this.props)

    hadGrafting(graftings, medicine, programDate) {
        return graftings.find(item => {
            if (medicine.WordID !== item.EvData.Medicine) return false;
            if (!!medicine && (medicine.WData.Type === medicineTypes.DOSATRON || medicine.WData.Type === medicineTypes.FORAGE)) {
                return programDate.isBetween(item.EvData.StartTime, item.EvData.EndTime, null, "[]");
            } else {
                return programDate.isSame(item.EvTime, "day");
            }
        });
    }

    getData() {
        let data = [];
        const {medicines, events, animal, cycle: cycleNumber, cycles, graftingProgram} = this.props;
        let date, maxDate;
        let isValidDateForGraftingProgram = false;
        let cycle = cycles[cycleNumber];
        if (animal.AnimalKind === AnimalTypes.SOW || animal.AnimalKind === AnimalTypes.RENOVATION_SOW) {
            date = cycle ? moment(cycle.StartTime) : moment(0);
            maxDate = cycle ? cycleNumber === 0 ? null : cycle.EndTime : null;
            isValidDateForGraftingProgram = !!cycle && !!cycle[EventTypes.INSEMINATION][0];
        } else {
            if (animal.DtaBrthTime || animal.DtaInTime) {
                date = moment(animal.DtaBrthTime || animal.DtaInTime).startOf("day");
                isValidDateForGraftingProgram = true;
            } else {
                date = moment(0); // ustawienie daty na 0 zeby brac wszystkie mozliwe treatmenty i wyswietlac
            }
        }
        let graftings = events.filter(item => {
            if (item.EvCode !== EventTypes.GRAFTING) return false;
            if (item.EvTime < date.clone().startOf("day").toDate().getTime()) return false;
            return maxDate ? item.EvTime < maxDate : true;
        });
        if (graftingProgram) {
            if (date && isValidDateForGraftingProgram) {
                data = graftingProgram.WData.MedicineList.map(row => {
                    let medicine = medicines.find(med => med.WordID === row.Medicine);
                    let programDate = date.clone().add(row.Age, "days");
                    if ((maxDate && programDate.toDate().getTime() < moment(maxDate).toDate().getTime()) || !maxDate) {
                        let hadGrafting = this.hadGrafting(graftings, medicine, programDate);
                        if (hadGrafting) {
                            graftings = graftings.filter(item => item.EvID !== hadGrafting.EvID);
                        }
                        return {
                            medicine,
                            date: programDate,
                            daysAfter: row.Age,
                            done: !!hadGrafting,
                            comment: hadGrafting ? hadGrafting.Comment : null,
                            operator: hadGrafting ? hadGrafting.OperID : null,
                            reason: hadGrafting ? hadGrafting.EvData.Reasn : null
                        }
                    }
                }).filter(item => item);
            }
        }
        let treatments = events.filter(item => item.EvCode === EventTypes.TREATMENT);
        for (let event of treatments) {
            for (let treatment of event.EvData.Treatment) {
                let dates = treatment.Dates.filter(item => {
                    if (maxDate && moment(item).isAfter(maxDate)) return false;
                    return moment(item).isAfter(date)
                });
                for (let medID of treatment.Medicine) {
                    let medicine = medicines.find(med => med.WordID === medID);
                    for (let d of dates) {
                        let trDate = moment(d).startOf("day");
                        let hadGrafting = this.hadGrafting(graftings, medicine, trDate);
                        if (hadGrafting) {
                            graftings = graftings.filter(item => item.EvID !== hadGrafting.EvID);
                        }
                        data.push({
                            medicine,
                            date: trDate,
                            daysAfter: graftingProgram ? trDate.diff(date, "day") + 1 : null,
                            done: !!hadGrafting,
                            comment: hadGrafting ? hadGrafting.Comment : null,
                            operator: hadGrafting ? hadGrafting.OperID : null,
                            reason: hadGrafting ? hadGrafting.EvData.Reasn : null
                        })
                    }
                }
            }
        }
        for (let grafting of graftings) {
            let medicine = medicines.find(med => med.WordID === get(grafting, `EvData.Medicine`));
            let d = grafting.EvData.StartTime ? moment(grafting.EvData.StartTime).startOf("day") : moment(grafting.EvTime).startOf("day");
            data.push({
                medicine,
                date: grafting.EvData.StartTime ? {Start: grafting.EvData.StartTime, End: grafting.EvData.EndTime} : d,
                done: true,
                daysAfter: isValidDateForGraftingProgram ? d.diff(date, "days") + 1 : null,
                comment: grafting.Comment,
                operator: grafting.OperID,
                reason: grafting.EvData.Reasn
            })
        }
        data.sort((a, b) => {
            if (a.date.Start && b.date.Start) {
                return a.date.Start - b.date.Start;
            } else if (a.date.Start) {
                return a.date.Start - b.date.toDate().getTime();
            } else if (b.date.Start) {
                return a.date.toDate().getTime() - b.date.Start;
            } else {
                return a.date.toDate().getTime() - b.date.toDate().getTime();
            }
        });
        return {data};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cycle !== prevProps.cycle || !isEqual(this.props.events, prevProps.events)) {
            this.setState(this.getData(this.props));
        }
    }

    dateValueFormatter = value => {
        if (value) {
            if (value.Start) {
                let text = moment(value.Start).format("DD.MM.YY") + " - ";
                if (value.End) {
                    text += moment(value.End).format("DD.MM.YY");
                } else {
                    text += "...";
                }
                return text;
            }
            return moment(value).format("DD.MM.YY");
        }
        return "-";
    };

    medicineValueFormatter = value => {
        if (value) return value.WData.Name;
        else return "-"
    };

    //todo - gdy unit bedzie trzymany jako typ, to dadac funkcjonalność.
    unitValueFormatter = value => {
        if (value) return value.WData.Dose + value.WData.Unit;
        else return "-";
    };

    employeesValueFormatter = value => {
        const {employees} = this.props;
        let user = employees.find(e => e.LocalUserID === value);
        if (user) return user.userName;
        else return "-";
    };

    userFormatter = value => {
        const {employees} = this.props;
        let user = employees.find(item => item.login === value);
        return user ? user.userName : value ? value : "";
    };

    reasonValueFormatter = value => {
        const {graftingReasons} = this.props;
        let reason = graftingReasons.find((reason) => reason.ID === value);
        if (reason) return reason.Value;
    };

    sortGridData = values => {
        const getTimeFromValue = (value) => value.date.constructor.name !== "Moment" ? value.date.Start : value.date.toDate().getTime();
        return values.sort((a, b) => {
            const firstDate = getTimeFromValue(a);
            const secondDate = getTimeFromValue(b);
            if (firstDate < secondDate) return 1;
            if (firstDate > secondDate) return -1;
            return 0;
        });
    };

    renderGrid() {
        const {t, animal} = this.props;
        const {data} = this.state;
        const headers = [
            {
                name: t("date"),
                field: "date",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
                _mobileDate: true
            },
            {
                name: t("day"),
                field: "daysAfter",
                headerComponent: <DateHeaderComponent
                    showFromInsemination={animal.AnimalKind === AnimalTypes.SOW || animal.AnimalKind === AnimalTypes.RENOVATION_SOW}/>
            },
            {
                name: t("medicine"),
                field: "medicine",
                valueFormatter: this.medicineValueFormatter,
                colWidth: 2,
            },
            {
                name: t("dosage"),
                field: "medicine",
                valueFormatter: this.unitValueFormatter
            },
            {
                name: t("reason"),
                field: "reason",
                valueFormatter: this.reasonValueFormatter
            },
            {
                name: t("done"),
                field: "done",
                component: props => <i className={props.value ? "fas fa-check success" : "fas fa-times error"}/>
            },
            {
                name: t("eventGrid.operator"),
                field: "operator",
                valueFormatter: this.userFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 2,
            },
            {
                name: t("comment"),
                field: "comment"
            }
        ];
        return (
            <>
                <div className="d-flex justify-content-between">
                    <h5>{t("treatment")}</h5>
                </div>
                <div>
                    <TableGrid data={this.sortGridData(data)} headers={headers} mobileRow={<DefaultMobileRow/>}
                               showPagination={true} paginationItems={10} scrollOnPageChange={false}/>
                </div>
            </>
        )
    }

    render() {
        const {showCard} = this.props;
        if (!showCard) return this.renderGrid();
        return (
            <Card>
                {this.renderGrid()}
            </Card>
        )
    }
}

GraftingProgramGridCard.propTypes = {
    cycle: PropTypes.number.isRequired,
    showCard: PropTypes.bool,
};

GraftingProgramGridCard.defaultProps = {
    showCard: true
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(GraftingProgramGridCard);
