import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {isEmpty} from "lodash";

export default class ActiveNipplesRenderer extends React.Component {

    render() {
        const {cycle: {activeNipples}, tooltipContent, cycleRenderClass} = this.props;
        let lastActiveNipples = activeNipples[activeNipples.length - 1];
        let nipples = lastActiveNipples && lastActiveNipples.nipples;
        let operators = [];
        if (!isEmpty(activeNipples)) operators = activeNipples[0].operators;
        return (
            <>
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent} isAdvanced={false} operators={operators}>
                    {nipples}
                </CycleRenderer>
                <CycleRenderer className="normal-display-none">
                    {nipples}
                </CycleRenderer>
            </>
        );
    }

}

ActiveNipplesRenderer.propTypes = {
    cycle: PropTypes.shape({
        nipples: PropTypes.array.isRequired
    }).isRequired
};
