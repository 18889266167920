import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {isEqual, sumBy} from "lodash";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";

export default class FallPigletsRenderer extends React.Component {

    weightValueFormatter = (value) => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1});
    };

    render() {
        const {cycle: {falls, key}, expanded: {rows}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let rowValue = rows.find(o => o === key);
        let lastFall = falls[falls.length - 1];
        if (!lastFall) return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none"/>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent}/>
                <CycleRenderer className="print-display-none"/>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none"/>
                }
                <CycleRenderer className="normal-display-none"/>
                <CycleRenderer className="normal-display-none"/>
            </>
        );
        const operators = falls[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {
                            isEqual(key, rowValue) && isAdvanced ? falls.map((fall, index) =>
                                <div key={index}>
                                    {fall.date}
                                </div>
                            ) : lastFall.date
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                    {
                        isAdvanced && isEqual(key, rowValue) ?
                            <div>
                                {
                                    falls.map((fall, index) =>
                                        <div key={index}>
                                            {fall.amount}
                                        </div>
                                    )
                                }
                            </div>
                            : <div>{sumBy(falls, 'amount')}</div>
                    }
                </CycleRenderer>
                <CycleRenderer className="print-display-none">
                    {
                        isAdvanced && isEqual(key, rowValue) ? falls.map((fall, index) =>
                            <div key={index}>
                                {this.weightValueFormatter(fall.weight)}
                            </div>
                        ) : this.weightValueFormatter(sumBy(falls, 'weight'))
                    }
                </CycleRenderer>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none">
                        {
                            falls.map((fall, index) =>
                                <div key={index}>
                                    {fall.date}
                                </div>
                            )
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                            falls.map((fall, index) =>
                                <div key={index}>
                                    {fall.amount}
                                </div>
                            ) : sumBy(falls, 'amount')
                    }
                </CycleRenderer>
                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                            falls.map((fall, index) =>
                                <div key={index}>
                                    {this.weightValueFormatter(fall.weight)}
                                </div>
                            ) : this.weightValueFormatter(sumBy(falls, 'weight'))
                    }
                </CycleRenderer>
            </>
        );
    }
}

FallPigletsRenderer.propTypes = {
    cycle: PropTypes.shape({
        falls: PropTypes.array.isRequired
    }).isRequired
};
