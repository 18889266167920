import React from "react";
import WeightingsGrid from "../weighting/WeightingsGrid";
import TransferGrid from "../transfer/TransferGrid";

class GroupCard extends React.Component {

    render() {
        return (
            <>
                <WeightingsGrid/>
                <TransferGrid/>
            </>
        );
    }

}

export default GroupCard;