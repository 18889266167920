import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {isEqual} from "lodash";

export default class InseminationRenderer extends React.Component {

    getBoarName() {
        try {
            const {cycle: {inseminations}} = this.props;
            const name = inseminations[0].boar;
            for (let insemination of inseminations) {
                if (insemination.boar !== name) return "Mix";
            }
            return name;
        } catch (e) {
            return "";
        }
    }

    render() {
        const {cycle: {inseminations, key}, expanded: {rows}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let rowValue = rows.find(o => o === key);
        let firstInsemination = inseminations[0];
        if (!firstInsemination) {
            return (
                <>
                    <CycleRenderer className={"print-display-none"} comment={cycleRenderClass && tooltipContent}/>
                    <CycleRenderer className={"print-display-none"}/>
                    <CycleRenderer className="normal-display-none"/>
                    <CycleRenderer className="normal-display-none"/>
                </>
            )
        }
        const operators = inseminations[0].operators;
        return (
            <>
                {
                    <CycleRenderer className="print-display-none" comment={tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                        {
                            isAdvanced && isEqual(key, rowValue) ?
                                <div>
                                    {
                                        inseminations.map((insemination, index) =>
                                            <div key={index}>
                                                {insemination.date}
                                            </div>
                                        )
                                    }
                                </div>
                                : <div>
                                    {
                                        !!firstInsemination && firstInsemination.date
                                    }
                                </div>
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none">
                    {
                        isAdvanced && isEqual(key, rowValue) ?
                            <div>
                                {
                                    inseminations.map((insemination, index) =>
                                        <div key={index}>
                                            {insemination.boar}
                                        </div>
                                    )
                                }
                            </div>
                            : <div>
                                {!!firstInsemination && this.getBoarName()}
                            </div>
                    }
                </CycleRenderer>
                {
                    <CycleRenderer className="normal-display-none">
                        {
                            isAdvanced ?
                                inseminations.map((insemination, index) =>
                                    <div key={index}>
                                        {insemination.date}
                                    </div>
                                ) : firstInsemination && firstInsemination.date
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                        inseminations.map((insemination, index) =>
                            <div key={index}>
                                {insemination.boar}
                            </div>
                        ) : this.getBoarName()
                    }
                </CycleRenderer>
            </>
        );
    }
}

InseminationRenderer.propTypes = {
    cycle: PropTypes.shape({
        inseminations: PropTypes.array.isRequired
    }).isRequired,
    showTitle: PropTypes.bool
};
