import React from "react";
import TransferGrid from "../transfer/TransferGrid";
import WeightingsGrid from "../weighting/WeightingsGrid";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";


export default class BoarCard extends React.Component {

    render() {
        return (
            <>
                <GraftingProgramGridCard/>
                <WeightingsGrid/>
                <TransferGrid/>
            </>
        )
    }
}
