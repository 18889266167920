import React, {Component} from 'react';
import {connect} from "react-redux";
import ViewContainerModal from "../basics/view-containter-modal/ViewContainerModal";
import {isMobile} from "../../utils/MobileUtils";
import {DocumentBody} from "./DocumentBody";
import EditAnimalBasicsModal from "../modals-new/edit-animal-basics/EditAnimalBasicsModal";
import ScrollTopButton from "../scroll-top-button/ScrollTopButton";
import ButtonGroup from "../basics/button/button-group/ButtonGroup";
import Button from "../basics/button/Button";
import {getAllDictionaries} from "../../actions/dictionaryActions";
import {AnimalTypes} from "../../constans/animalTypes";
import {withTranslation} from "react-i18next";
import TechnologyGroup from "../../beans/TechnologyGroup";
import {startCase, toLower} from "lodash";
import moment from "moment";

const style = {zIndex: 5};

class Document extends Component {

    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        dispatch(getAllDictionaries());
    }

    getName = () => {
        const {selectedItem, t} = this.props;
        if (selectedItem) {
            switch (selectedItem.AnimalKind) {
                case AnimalTypes.SOW :
                    return t("animalDocuments._sow") + " " + selectedItem.AnmNo1;
                case AnimalTypes.BOAR:
                    return t("boar") + " " + selectedItem.AnmNo1;
                case AnimalTypes.PIGLET:
                    return t("animalDocuments._piglet") + " " + selectedItem.AnmNo1;
                case AnimalTypes.RENOVATION_SOW:
                    return t("animalDocuments.renovation_sow") + " " + selectedItem.AnmNo1;
                case AnimalTypes.PORKER:
                    return t("animalDocuments._porker") + " " + selectedItem.AnmNo1;
                default:
                    if (selectedItem.GrNo1) {
                        return `${startCase(toLower(t("group")))} ${selectedItem.GrNo1}`;
                    }
                    return `${startCase(toLower(t("technologyGroup")))} ${moment(selectedItem.StartTime).format("DD.MM.YY")}`;
            }
        } else {
            return '-';
        }
    };

    shouldShow = ({scrollTop}) => !this.props.selectedItem && (scrollTop > 100);

    render() {
        const {selectedItem, events, handleNext, handlePrev, handleHide, loadingDocuments, loadingTechno} = this.props;
        const mobile = isMobile();
        const loading = selectedItem instanceof TechnologyGroup ? loadingTechno || loadingDocuments : loadingDocuments;
        const canUseNext = handleNext(false);
        const canUsePrev = handlePrev(false);
        return (
            <>
                <ViewContainerModal onHide={handleHide} loading={!!loading || !selectedItem}
                                    name={this.getName()}
                                    show={!!selectedItem} addPaddingForButtons>
                    <DocumentBody selectedItem={selectedItem} events={events} mobile={mobile}/>
                </ViewContainerModal>
                <ScrollTopButton show={this.shouldShow}/>
                <EditAnimalBasicsModal/>
                {
                    <ButtonGroup show={!!selectedItem && (!!handleNext && !!handlePrev)} style={style} fixed
                                 renderInPortal={true}>
                        <Button type={"button"} onClick={() => handlePrev(true)} buttonColor={"info"}
                                buttonStyle={"round"}
                                icon={<i className="fas fa-arrow-left"/>} disabled={loading || !canUsePrev}/>
                        <Button type={"button"} onClick={() => handleNext(true)} buttonColor={"info"}
                                buttonStyle={"round"}
                                icon={<i className="fas fa-arrow-right"/>} disabled={loading || !canUseNext}/>
                    </ButtonGroup>
                }
            </>
        )
    }
}

Document = connect(state => ({
    selectedItem: state.animalDocuments.selectedItem,
    events: state.animalDocuments.events,
    loadingDocuments: state.animalDocuments.fetchingEvent,
    loadingTechno: state.technologyGroup.fetching
}))(Document);
export default withTranslation()(Document);
