import React from "react";
import {Redirect, Route} from "react-router-dom";
import Loading from "./loading/LoadingComponent"

/**
 *Metoda zabezpieczajaca przed przejsciem do stron gdze zalogowany uzytkownik
 * nie powniene miec dojscia np. panel logowania
 *
 * Here we are checking to ensure that the user
 * is not authenticated before we render the
 * component that is passed in. And in the case
 * where the user is authenticated, we use the Redirect
 * component to simply send the user to the homepage.
 * */
export default ({component: C, props: cProps, ...rest}) => {
    const redirect = querystring("redirect");
    //console.log("UNAUTH PATH", cProps);
    return (
        <Route
            {...rest}
            render={props =>
                cProps.isAuthenticating ? <Loading isLoading={cProps.isAuthenticating}/> : !cProps.isAuthenticated
                    ? <C {...props} {...cProps}/>
                    : <Redirect
                        to={!redirect ? "/" : redirect}
                    />}
        />
    );
};

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}