import React, {Component} from 'react';
import BasicInfoBox from "./BasicInfoBox";
import {AnimalTypes} from "../../constans/animalTypes";
import SowCard from "./cards/SowCard";
import BoarCard from "./cards/BoarCard";
import PigletPorkerCard from "./cards/PigletPorkerCard";
import Animal from "../../beans/Animal";
import Group from "../../beans/Group";
import BasicInfoGroupBox from "./BasicInfoGroupBox";
import GroupCard from "./cards/GroupCard";
import TechnologyGroup from "../../beans/TechnologyGroup";
import AnimalsInTechnologyGroupGrid from "../grids/animals-in-technology-group/AnimalsInTechnologyGroupGrid";
//import {getUpdateCycleList} from "../../actions/eventsActions";
import {connect} from "react-redux";
import RenovationSowCard from "./cards/RenovationSowCard";

export class DocumentBody extends Component {

    // TODO - wtkomentowane bo nie wiem po co potrzebne
    // componentDidMount() {
    //     this.props.dispatch(getUpdateCycleList(this.props.selectedItem));
    // }

    render() {
        const {selectedItem, events, mobile, loading} = this.props;

        return (
            <>
                {
                    (!loading) && (selectedItem instanceof Animal) &&
                    <>
                        <BasicInfoBox showEdit={true} events={events} animal={selectedItem}/>
                        {
                            (selectedItem.AnimalKind !== undefined) &&
                            <>
                                {
                                    selectedItem.AnimalKind === AnimalTypes.RENOVATION_SOW &&
                                    <RenovationSowCard/>
                                }
                                {
                                    selectedItem.AnimalKind === AnimalTypes.SOW &&
                                    <SowCard mobile={mobile}/>
                                }
                                {
                                    selectedItem.AnimalKind === AnimalTypes.BOAR &&
                                    <BoarCard/>
                                }
                                {
                                    (selectedItem.AnimalKind === AnimalTypes.PORKER || selectedItem.AnimalKind === AnimalTypes.PIGLET) &&
                                    <PigletPorkerCard/>
                                }
                            </>
                        }

                    </>
                }
                {
                    (!loading) && (selectedItem instanceof Group) &&
                    <>
                        <BasicInfoGroupBox/>
                        <GroupCard/>
                    </>
                }
                {
                    (!loading) && (selectedItem instanceof TechnologyGroup) &&
                    <>
                        <AnimalsInTechnologyGroupGrid/>
                        <AnimalsInTechnologyGroupGrid showRemoved/>
                    </>
                }
            </>
        )
    }
}

DocumentBody = connect(state => ({
    selectedItem: state.animalDocuments.selectedItem,
}))(DocumentBody);
