import React, {Component} from 'react';
import Card from "../../basics/card/Card";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import PigletsTreatmentCard from "../pigets-treatment/PigletsTreatmentCard";
import Select from "../../basics/select/Select";
import {connect} from 'react-redux';
import {getCycles} from "../../../selectors/animalDocumentsSelectors";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import "./sow-treatment.scss"

function mapStateToProps(state) {
    return {
        cycles: getCycles(state).sort((a, b) => b.cycle - a.cycle),
    };
}

class SowTreatment extends Component {

    state = {
        cycle: 0
    }

    onSelectChange = value => {
        this.setState({
            cycle: value
        })
    }

    render() {
        const {cycles, t} = this.props;
        const {cycle} = this.state;
        return (
            <Card>
                <Select options={cycles.map((cycle, index) => ({name: t("cycle", {count: cycle.cycle}), value: index}))}
                        clearButton={false} value={cycle} onChange={this.onSelectChange}/>
                <div className="sow-grafting-list">
                    <GraftingProgramGridCard cycle={cycle} showCard={false}/>
                </div>
                <PigletsTreatmentCard cycle={cycle}/>
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(SowTreatment);