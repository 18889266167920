import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {isEqual} from "lodash";

export default class WeightingSowRenderer extends React.Component {

    weightValueFormatter = (value) => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 1});
    };

    render() {
        const {cycle: {weightings, key}, expanded: {rows}, isAdvanced} = this.props;
        let rowValue = rows.find(o => o === key);
        let lastWeighting = weightings[weightings.length - 1];
        if (!lastWeighting) {
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer/>
                    }
                    <CycleRenderer/>
                </>
            )
        }
        const operators = weightings[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {
                            isEqual(key, rowValue) ? weightings.map((weighting, index) =>
                                <div key={index}>
                                    {weighting.date}
                                </div>
                            ) : lastWeighting.date
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none" isAdvanced={isAdvanced} operators={operators}>
                    {
                        isAdvanced && isEqual(key, rowValue) ? weightings.map((weighting, index) =>
                            <div key={index}>
                                {this.weightValueFormatter(weighting.weight)}
                            </div>
                        ) : this.weightValueFormatter(lastWeighting.weight)
                    }
                </CycleRenderer>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none">
                        {
                            weightings.map((weighting, index) =>
                                <div key={index}>
                                    {weighting.date}
                                </div>
                            )
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                        weightings.map((weighting, index) =>
                            <div key={index}>
                                {this.weightValueFormatter(weighting.weight)}
                            </div>
                        ) : this.weightValueFormatter(lastWeighting.weight)
                    }
                </CycleRenderer>
            </>
        );
    }

}

WeightingSowRenderer.propTypes = {
    cycle: PropTypes.shape({
        weightings: PropTypes.array.isRequired
    }).isRequired
};
