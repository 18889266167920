import React from "react";
import PropTypes from "prop-types"
import CycleRenderer from "./CycleRenderer";

export default class ParturitionRenderer extends React.Component {


    render() {
        const {cycle: {parturitions}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let parturition = parturitions[0];
        if (!parturition) return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none"/>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent}/>
                <CycleRenderer className="print-display-none"/>
                <CycleRenderer className="print-display-none"/>
                <CycleRenderer className="print-display-none"/>

                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none"/>
                }
                <CycleRenderer className="normal-display-none"/>
                <CycleRenderer className="normal-display-none"/>
                <CycleRenderer className="normal-display-none"/>
                <CycleRenderer className="normal-display-none"/>
            </>
        );
        const operators = parturitions[0].operators;
        return (
            <>
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer className="print-display-none">
                            {parturition.date}
                        </CycleRenderer>
                    }
                    <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                        <div>{parturition.alive}</div>
                    </CycleRenderer>
                    <CycleRenderer className="print-display-none">
                        {parturition.dead}
                    </CycleRenderer>
                    <CycleRenderer className="print-display-none">
                        {parturition.mummies}
                    </CycleRenderer>
                    <CycleRenderer className="print-display-none">
                        {parturition.avgWeight}
                    </CycleRenderer>
                    {
                        isAdvanced &&
                        <CycleRenderer className={"normal-display-none"}>
                            {parturition.date}
                        </CycleRenderer>
                    }
                    <CycleRenderer className={"normal-display-none"}>
                        {parturition.alive}
                    </CycleRenderer>
                    <CycleRenderer className={"normal-display-none"}>
                        {parturition.dead}
                    </CycleRenderer>
                    <CycleRenderer className={"normal-display-none"}>
                        {parturition.mummies}
                    </CycleRenderer>
                    <CycleRenderer className={"normal-display-none"}>
                        {parturition.avgWeight}
                    </CycleRenderer>
                </>
            </>
        );
    }

}

ParturitionRenderer.propTypes = {
    cycle: PropTypes.shape({
        parturitions: PropTypes.array.isRequired
    }).isRequired
};
