import React from "react";
import PropTypes from "prop-types"
import CycleRenderer from "./CycleRenderer";
import {connect} from "react-redux";

export class CycleNumberRenderer extends React.Component {

    getCellBackground = () => {
        const {cycleTable, cycle: {cycle}} = this.props;
        const howManyCycles = cycleTable.filter(row => row.cycle === cycle).length;
        if (howManyCycles > 3) return "error";
        if (howManyCycles >= 2) return "warning";
        return "";
    };


    render() {
        const {cycle: {cycle}} = this.props;
        const name = this.getCellBackground();
        return (
            <CycleRenderer className={name}>
                {cycle}
            </CycleRenderer>
        );
    }

}

CycleNumberRenderer.propTypes = {
    cycle: PropTypes.isRequired
};

CycleNumberRenderer = connect((state) => ({
    cycleTable: state.animalDocuments.cycleTable
}))(CycleNumberRenderer);

export default CycleNumberRenderer;
