import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";

export default class ExpandRenderer extends React.Component {

    constructor(props) {
        super(props);
        const {expanded: {rows}, cycle: {key}} = props;
        const previousClick = !!rows.includes(key);
        this.state = {
            opened: previousClick,
        }
    }

    onClick = () => {
        const {cycle: {key}, onPress} = this.props;
        this.setState((state) => ({
            opened: !state.opened,
        }), () => onPress(key, this.state.opened));
    };

    shouldExpand = () => {
        const {cycle} = this.props;
        for (const property in cycle) {
            if (cycle.hasOwnProperty(property) && property !== "invalidEvents" && Array.isArray(cycle[property]) && cycle[property].length > 1) {
                return true;
            }
        }
        return false;
    };

    render() {
        const {opened} = this.state;
        const shouldExpand = this.shouldExpand();
        if (shouldExpand) {
            return (
                <>
                    <CycleRenderer className="print-display-none">
                        <i onClick={this.onClick} className={`fas fa-chevron-${opened ? 'up' : 'down'}`}/>
                    </CycleRenderer>
                </>
            );
        } else {
            return <CycleRenderer className="print-display-none"/>
        }
    }

}

ExpandRenderer.propTypes = {
    cycle: PropTypes.shape({
        shouldExpand: PropTypes.bool.isRequired
    }).isRequired
};

