import React from "react";
import TransferGrid from "../transfer/TransferGrid";
import WeightingsGrid from "../weighting/WeightingsGrid";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import HeatList from "../../grids/animal-documents/HeatList";

export default class RenovationSowCard extends React.Component {

    render() {
        return (
            <>
                <GraftingProgramGridCard/>
                <HeatList/>
                <WeightingsGrid/>
                <TransferGrid/>
            </>
        );
    }

}
