import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {getAnimalEventsWithType, getCycles} from "../../../selectors/animalDocumentsSelectors";
import {EventTypes} from "../../../constans/eventTypes";
import TableGrid from "../../basics/table-grid/TableGrid";
import {isEqual} from "lodash";
import moment from "moment";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";
import PropTypes from "prop-types";

function mapStateToProps(state) {
    return {
        events: getAnimalEventsWithType(state, EventTypes.PIGLETS_TREATMENT),
        cycles: getCycles(state).sort((a, b) => b.cycle - a.cycle),
        medicines: state.dictionary.medicine,
        employees: state.user.employees,
        graftingReasons: state.dictionary.graftingReason.WData[state.language.locale]
    };
}

class PigletsTreatmentCard extends Component {

    state = {
        treatments: []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cycle !== prevProps.cycle || !isEqual(this.props.events, prevProps.events)) {
            this.setState({
                treatments: this.getPigletsTreatments(this.props.cycle)
            })
        }
    }

    mapPigletTreatments = event => ({
        date: event.EvData.StartTime ? {Start: event.EvData.StartTime, End: event.EvData.EndTime} : event.EvTime,
        pigletsAmount: event.EvData.PiCnt,
        medicine: event.EvData.Medicine,
        operator: event.OperID,
        comment: event.Comment,
        reason: event.EvData.Reasn
    })

    getPigletsTreatments(cycle) {
        const {events} = this.props;
        if (!this.props.cycles.length) return [];
        const {StartTime, EndTime} = this.props.cycles[cycle];
        if (cycle === 0) {
            return events.filter(item => item.EvTime >= StartTime).map(this.mapPigletTreatments);
        }
        return events.filter(item => item.EvTime >= StartTime && item.EvTime <= EndTime).map(this.mapPigletTreatments);
    }

    dateFormatter = value => {
        if (value.Start) {
            if (value.End) return moment(value.Start).format("DD.MM.YYYY") + " - " + moment(value.End).format("DD.MM.YYYY");
            return moment(value.Start).format("DD.MM.YYYY") + " - ...";
        }
        return moment(value).format("DD.MM.YYYY");
    }

    medicineFormatter = value => {
        const {medicines} = this.props;
        let medicine = medicines.find(item => item.WordID === value);
        if (medicine) {
            return medicine.WData.Name
        }
    };

    userFormatter = value => {
        const {employees} = this.props;
        let user = employees.find(item => item.login === value);
        return user ? user.userName : value ? value : "";
    };

    reasonValueFormatter = value => {
        const {graftingReasons} = this.props;
        let reason = graftingReasons.find((reason) => reason.ID === value);
        if (reason) return reason.Value;
    };

    render() {
        const {t} = this.props;
        const {treatments} = this.state;
        const headers = [
            {
                name: t("date"),
                field: "date",
                valueFormatter: this.dateFormatter
            },
            {
                name: t("pigletsAmount"),
                field: "pigletsAmount"
            },
            {
                name: t("medicine"),
                field: "medicine",
                valueFormatter: this.medicineFormatter,
                colWidth: 2
            },
            {
                name: t("reason"),
                field: "reason",
                valueFormatter: this.reasonValueFormatter,
                colWidth: 2,
            },
            {
                name: t("eventGrid.operator"),
                field: "operator",
                valueFormatter: this.userFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 2
            },
            {
                name: t("comment"),
                field: "comment"
            }
        ];
        return (
            <>
                <h5>{t("pigletsTreatment")}</h5>
                <TableGrid data={treatments.sort((a, b) => b.EvData.StartTime - a.EvData.StartTime)}
                           headers={headers} mobileRow={<DefaultMobileRow/>} showPagination={true} paginationItems={10}
                           scrollOnPageChange={false}/>
            </>
        );
    }
}

PigletsTreatmentCard.propTypes = {
    cycle: PropTypes.number.isRequired
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(PigletsTreatmentCard);
