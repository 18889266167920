import React from "react";
import "./_analog-clock.scss"
import PropTypes from "prop-types";
import moment from "moment";

export default class AnalogClock extends React.Component {

    render() {
        const {time: timestamp} = this.props;
        let time = moment(timestamp);
        let seconds = (time.seconds() % 60) * 6;
        let minutes = (time.minutes() % 60) * 6;
        let hours = (time.hours() % 12) * 30;
        console.log(time);
        return (
            <div className={"analog-clock"}>
                <div className={"analog-clock-container"}>
                    <div className={"pointer hours"} style={{transform: `rotateZ(${hours}deg)`}}/>
                    <div className={"pointer minutes"} style={{transform: `rotateZ(${minutes}deg)`}}/>
                    <div className={"pointer seconds"} style={{transform: `rotateZ(${seconds}deg)`}}/>

                </div>
            </div>
        );
    }

}

AnalogClock.propTypes = {
    time: PropTypes.object
};
