import React, {Component} from 'react';
import Tooltip from "../../basics/tooltip/Tooltip";
import {withTranslation} from "react-i18next";

class DateHeaderComponent extends Component {
    render() {
        const {name, t, showFromInsemination} = this.props;
        return (
            <div>
                {name}&nbsp;
                <Tooltip
                    tooltipContent={showFromInsemination ? t("animalDocuments.calculatedFromInsemination") : t("animalDocuments.calculatedFromBirth")}>
                    <i className="fas fa-info-circle"/>
                </Tooltip>
            </div>
        );
    }
}

export default withTranslation()(DateHeaderComponent);