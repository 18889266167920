import React, {Component} from "react";
import EditSowCycleModal from "../../modals-new/edit-sow-cycle/EditSowCycleModal";
import HeatList from "../../grids/animal-documents/HeatList";
import TransferGrid from "../transfer/TransferGrid";
import WeightingsGrid from "../weighting/WeightingsGrid";
import SowCardGridMinimal from "../../grids/animal-documents/SowCardGridMinimal";
import SowCardGridMobile from "../../grids/animal-documents/SowCardGridMobile";
import SowTreatment from "../sow-treatment/SowTreatment";

export default class SowCard extends Component {

    render() {
        const {mobile} = this.props;
        return (
            <>
                {
                    !mobile &&
                    <SowCardGridMinimal/>
                }
                {
                    mobile &&
                    <SowCardGridMobile/>
                }
                <SowTreatment/>
                <HeatList/>
                <WeightingsGrid/>
                <TransferGrid/>
                <EditSowCycleModal/>
            </>
        )
    }
}
