import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {isEqual, sumBy} from "lodash";

export default class MommyRenderer extends React.Component {

    render() {
        const {cycle: {mommys, key}, expanded: {rows}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let rowValue = rows.find(o => o === key);
        let lastMommy = mommys[mommys.length - 1];
        if (!lastMommy) return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none"/>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent}/>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none"/>
                }
                <CycleRenderer className="normal-display-none"/>
            </>
        );
        const operators = mommys[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {
                            isEqual(key, rowValue) ? mommys.map((mommy, index) =>
                                <div key={index}>
                                    {mommy.date}
                                </div>
                            ) : lastMommy.date
                        }
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                    {
                        isAdvanced && isEqual(key, rowValue) ?
                                <div>
                                    {
                                        mommys.map((mommy, index) =>
                                            <div key={index}>
                                                {mommy.amount}
                                            </div>
                                        )
                                    }
                                </div>
                            : <div>{sumBy(mommys, 'amount')}</div>
                    }
                </CycleRenderer>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none">
                        {
                            mommys.map((mommy, index) =>
                                <div key={index}>
                                    {mommy.date}
                                </div>
                            )
                        }
                    </CycleRenderer>
                }

                <CycleRenderer className="normal-display-none">
                    {
                        isAdvanced ?
                        mommys.map((mommy, index) =>
                            <div key={index}>
                                {mommy.amount}
                            </div>
                        ) : sumBy(mommys, 'amount')
                    }
                </CycleRenderer>
            </>
        );
    }
}

MommyRenderer.propTypes = {
    cycle: PropTypes.shape({
        mommys: PropTypes.array.isRequired
    }).isRequired
};
