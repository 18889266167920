import React, {Component} from 'react';
import moment from "moment";
import "./_alarm-details-body.scss"
import {withTranslation} from "react-i18next";

class AlarmDetailsBody extends Component {
    getPrefix = (alarms) => {
        const alarmsLength = alarms.length;
        let evaluatingIndex = 0;
        for (evaluatingIndex; evaluatingIndex < 4; evaluatingIndex++) {
            for (let i = 0; i < alarmsLength; i++) {
                for (let j = 0; j < alarms[i].subtitle.length; j++) {
                    if ((!alarms[0].subtitle[0] || !alarms[0].subtitle[0][evaluatingIndex]) || (alarms[0].subtitle[0][evaluatingIndex] !== alarms[i].subtitle[j][evaluatingIndex])) {
                        evaluatingIndex--;
                        return {
                            prefix: evaluatingIndex === -1 ? [] : alarms[0].subtitle[0].slice(0, evaluatingIndex),
                            startFrom: evaluatingIndex
                        }
                    }
                }
            }
        }
        return {
            prefix: alarms[0] && alarms[0].subtitle[0] ? alarms[0].subtitle[0].slice(0, evaluatingIndex > 0 ? evaluatingIndex : undefined) : [],
            startFrom: evaluatingIndex
        }
    };

    render() {
        const {alarm, t} = this.props;
        if (!alarm) return null;
        const {_passThrough: {other}, subtitle: places, title} = alarm;
        const {prefix, startFrom} = this.getPrefix(other);
        return (
            <div className={"alarm-details-body"}>
                <p className={"font-weight-bold"}>{title}</p>
                <p className={"font-weight-bold"}>{places.length === 0 ? t("missingLocation") : places.length > 1 ? prefix.join(" - ") : places[0].join(" - ")}</p>

                <div className={"table-responsive w-100"}>
                    <table className={"w-100"}>
                        <thead>
                        <tr>
                            <th>
                                {t("time")}
                            </th>
                            {places.length > 1 &&
                            <th>
                                {t("placement")}
                            </th>
                            }

                            <th>
                                {t("modals.alarmDetailsModal.description")}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            other.map(a => {
                                const {timestamp, text, subtitle} = a;
                                return (
                                    <tr>
                                        <td>{moment(timestamp).format("DD.MM.YY HH:mm")}</td>
                                        {places.length > 1 &&
                                        <td>{subtitle.map(o => o.slice(startFrom + 1).join(" - ")).join("-")}</td>}
                                        <td>{t(text.key, text.params)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>


            </div>
        )
    }
}

export default withTranslation()(AlarmDetailsBody);
