import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {connect} from "react-redux";

export class NoPregnancyRenderer extends React.Component {

    render() {
        const {cycle: {noPregnancy}, isAdvanced, noPregnancyReason, lang} = this.props;
        const nP = noPregnancy[0];
        if (!nP) {
            return (
                <>
                    {
                        isAdvanced && <CycleRenderer className="print-display-none"/>
                    }
                    <CycleRenderer className="print-display-none last-cell"/>
                </>
            )
        }
        const reason = noPregnancyReason.WData[lang].find((item) => item.ID === nP.reason);
        const operators = noPregnancy[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {nP.date}
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none last-cell" isAdvanced={isAdvanced} operators={operators}>
                    {reason && reason.Value}
                </CycleRenderer>
            </>
        )
    }
}

NoPregnancyRenderer = connect(state => ({
    noPregnancyReason: state.dictionary.noPreganancy,
    lang: state.language.lang.lang
}))(NoPregnancyRenderer);

NoPregnancyRenderer.propTypes = {
    cycle: PropTypes.shape({
        noPregnancy: PropTypes.array.isRequired
    }).isRequired
};

export default NoPregnancyRenderer;
