import React from "react";
import {connect} from "react-redux";
import Card from "../../basics/card/Card";
import moment from "moment";
import {sortDateStrings} from "../../../utils/SortUtils";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import TableGrid from "../../basics/table-grid/TableGrid";
import {EventTypes} from "../../../constans/eventTypes";
import {withTranslation} from "react-i18next";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";

class WeightingsGrid extends React.Component {

    dateValueFormatter = value => {
        return value ? moment(value).format("DD.MM.YY") : "";
    };

    weightValueFormatter = value => {
        return value ? convertWeightUnitTo(+value, {unit: UnitTypes.MEDIUM, showUnit: true}) : "";
    };

    employeesValueFormatter = value => {
        const {employees} = this.props;
        let user = employees.find(e => e.LocalUserID === value);
        return user ? user.userName : value ? value : "";
    };

    getWeightings = value => value.filter(e => [EventTypes.WEIGHTING].includes(e.EvCode));

    render() {
        const {t, events} = this.props;
        const _events = this.getWeightings(events.length ? events : []);
        const headers = [
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
                _mobileDate: true
            },
            {
                name: t("animalDocuments.averageWeight"),
                field: "EvData.Weight",
                valueFormatter: this.weightValueFormatter
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 4
            }
        ];
        return (
            <Card>
                <div className="d-flex justify-content-between">
                    <h5>{t("weighting")}&nbsp;</h5>
                </div>
                <div>
                    <TableGrid data={_events.sort((a, b) => b.EvTime - a.EvTime)} headers={headers}
                               mobileRow={<DefaultMobileRow/>} showPagination={true} paginationItems={10}
                               scrollOnPageChange={false}/>
                </div>
            </Card>
        );
    }


}

WeightingsGrid = connect((state) => ({
    employees: state.user.employees,
    events: state.animalDocuments.events,
}))(WeightingsGrid);

export default withTranslation()(WeightingsGrid);
