import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";

export default class SeparationRenderer extends React.Component {

    render() {
        const {cycle: {separations, sowCycle}, isAdvanced, tooltipContent, cycleRenderClass} = this.props;
        let separation = separations[0];
        let type = cycleRenderClass && cycleRenderClass.split(" ")[1];
        if (!separation) {
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer className="print-display-none"/>
                    }
                    <CycleRenderer className="print-display-none" comment={cycleRenderClass && tooltipContent} type={type}/>
                    <CycleRenderer className="print-display-none"/>
                    {
                        isAdvanced &&
                        <CycleRenderer className="normal-display-none"/>
                    }
                    <CycleRenderer className="normal-display-none"/>
                    <CycleRenderer className="normal-display-none"/>
                </>
            );
        }
        const operators = separations[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        {separation.date}
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none" type={type} sowCycle={sowCycle} comment={cycleRenderClass && tooltipContent} isAdvanced={isAdvanced} operators={operators}>
                        {separation.amount}
                </CycleRenderer>
                <CycleRenderer className="print-display-none">
                    {separation.weight}
                </CycleRenderer>
                {
                    isAdvanced &&
                    <CycleRenderer className="normal-display-none">
                        {separation.date}
                    </CycleRenderer>
                }
                <CycleRenderer className="normal-display-none">
                    {
                        separation.amount
                    }
                </CycleRenderer>
                <CycleRenderer className="normal-display-none">
                    {
                        separation.weight
                    }
                </CycleRenderer>
            </>
        );
    }

}

SeparationRenderer.propTypes = {
    cycle: PropTypes.shape({
        separations: PropTypes.array.isRequired
    }).isRequired
};
