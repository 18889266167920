import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {EventTypes} from "../../constans/eventTypes";
import _ from "lodash";
import {AnimalTypes} from "../../constans/animalTypes";
import {Col, Row} from "react-bootstrap";
import animalsDB from "../../database/animalsDB"
import "./css/_basic-info-box.scss"
import Card from "../basics/card/Card";
import {ModalName as EditAnimalBasicsModalName} from "../modals-new/edit-animal-basics/EditAnimalBasicsModal";
import Button from "../basics/button/Button";
import PropTypes from "prop-types"
import {convertWeightUnitTo} from "../../utils/UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";
import {getNumberOfDaysToSale} from "../../utils/SettingsUtils";
import technologyGroupsDB from "../../database/technologyGroupsDB";
import {getPigBalance, getPlannedParturitionForASow} from "../../utils/EventUtils";
import {convertRowsToCycles} from "../../utils/AnimalDocumentsUtils";
import {withTranslation} from "react-i18next";

export class BasicInfoBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            separations: this.props.events.length > 0 ? this.props.events.filter(ev => [EventTypes.SEPARATION].includes(ev.EvCode)) : []
        }
    }

    getSupplier() {
        try {
            let supplier = this.props.suppliers.find(item => item.ID === this.props.animal.SupplierID);
            return supplier.Value;
        } catch (e) {
            return "-";
        }
    }

    getRace() {
        try {
            let race = this.props.races.find(item => item.ID === this.props.animal.Race);
            return race.Value;
        } catch (e) {
            return "-";
        }
    }

    getDeathReason() {
        let type = -1;
        if (this.props.animal && this.props.animal.DtaDthTime) {
            let filtered = _.cloneDeep(this.props.events);
            type = this.props.animal.DthRsn;
            filtered = filtered ? filtered : [];
            filtered.sort((b, a) => (a.EvTime ? +a.EvTime : 0) - (b.EvTime ? +b.EvTime : 0));
            filtered = filtered.filter(event => event.EvCode === (type === 1 ? EventTypes.SELL : EventTypes.FALL))[0];
            if (filtered) {
                if (filtered.EvCode === EventTypes.SELL && filtered.EvData) {
                    let tmp = this.props.clients.filter(client => `${client.ID}` === `${filtered.EvData.Buyer}`)[0];
                    if (tmp) {
                        return {type: type, value: tmp.Value};
                    }
                }
                if (filtered.EvCode === EventTypes.FALL && filtered.EvData) {
                    let tmp = this.props.fallReasons.filter(reason => `${reason.ID}` === `${filtered.EvData.Reasn}`)[0];
                    if (tmp) {
                        return {type: type, value: tmp.Value};
                    }
                }
            }
        }
        return {type: type, value: "-"};
    }

    getLocation() {
        const {animal} = this.props;
        let locations = animalsDB.getAnimalLocationsByPlcmntID(animal.PlcmntID);
        let texts = locations.map(location => location.name);
        return texts.join(", ");
    }

    getBirth(birthTime) {
        let date = moment(birthTime);
        let age = moment().startOf("day").diff(birthTime, "days");
        return `${date.format("DD.MM.YYYY")} (${age} ${age === 1 ? this.props.t("day") : this.props.t("days")})`;
    }

    getAverageWeightOfBirth() {
        const {Weight, AnmCnt} = this.props.animal;
        const averageWeight = AnmCnt ? (+Weight / +AnmCnt) : 0;
        return averageWeight ? convertWeightUnitTo(averageWeight, {unit: UnitTypes.MEDIUM, showUnit: true}) : "-";
    }

    getAverageWeightOfSeparation() {
        const {separations} = this.state;
        if (separations.length === 0) {
            return "-"
        }
        const data = separations.reduce((prev, next) => {
            return {
                weight: prev.weight + next.EvData.PiWeight,
                cnt: prev.cnt + next.EvData.PiCnt
            }
        }, {weight: 0, cnt: 0});
        return data.cnt ? convertWeightUnitTo((data.weight / data.cnt), {unit: UnitTypes.MEDIUM, showUnit: true}) : "-";
    }

    getLastDateOfSeparation() {
        const {separations} = this.state;
        const date = separations.length ? Math.max.apply(null, separations.map((e) => {
            return e.EvTime
        })) : 0;
        return date ? moment(date).format("DD.MM.YYYY") : "-";
    }

    getLastActiveNipples = () => {
        const {cycleTable} = this.props;
        if (cycleTable[cycleTable.length - 1] && cycleTable[cycleTable.length - 1].AN[0] && cycleTable[cycleTable.length - 1].AN[0].EvData) {
            return cycleTable[cycleTable.length - 1].AN[cycleTable[cycleTable.length - 1].AN.length - 1].EvData.Nipples;
        }
        return '-'
    };

    getExpectedDateOfSale() {
        const {animal: {DtaBrthTime}} = this.props;
        let daysToSale = +getNumberOfDaysToSale();
        return DtaBrthTime ? moment(DtaBrthTime).add(daysToSale, "days").format("DD.MM.YYYY") : "-";
    }

    getCurrentPigletsForASow() {
        const {cycleTable} = this.props;
        const cycles = convertRowsToCycles(cycleTable);
        const events = [];
        if (!_.isEmpty(cycles)) {
            const currentCycle = cycles[cycles.length - 1];
            Object.values(currentCycle).forEach(arr => {
                if (Array.isArray(arr)) {
                    arr.forEach(el => events.push(el));
                }
            });
            return getPigBalance(events);
        }
        return 0;
    }

    getTechnologyGroup = () => {
        const {animal: {AnmID}} = this.props;
        return technologyGroupsDB.getTechnoGroupById(AnmID);
    };

    render() {
        const {animal, showEdit, cycleTable} = this.props;
        const technologyGroup = this.getTechnologyGroup();
        let dthReason = this.getDeathReason();
        return (
            <Card className="basic-info-box">
                <div className="d-flex justify-content-between">
                    <h5>
                        {this.props.t("animalDocuments.basicInformation")}
                    </h5>
                    {
                        showEdit &&
                        <Button buttonStyle="round" icon={<i className="fas fa-pencil-alt"/>}
                                onClick={() => this.props.show(EditAnimalBasicsModalName, {animal})}/>
                    }
                </div>
                    <div id="info-box">
                        <Row>
                            <Col md={6}>
                                <div>
                                    <div className="info-row">
                                        <div className={"info-column header"}>
                                            {this.props.t("animalNumber")}
                                        </div>
                                        <div className="info-column">
                                            {animal.AnmNo1}
                                        </div>
                                    </div>
                                    <div className="info-row">
                                        <div className={"info-column header"}>
                                            {this.props.t("secondaryNumber")}
                                        </div>
                                        <div className="info-column">
                                            {animal.AnmNo2 || "-"}
                                        </div>
                                    </div>
                                    <div className="info-row">
                                        <div className={"info-column header"}>
                                            {this.props.t("rfidNumber")}
                                        </div>
                                        <div className="info-column">
                                            {animal.RFID || "-"}
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.props.animal.AnimalKind === AnimalTypes.PORKER &&
                                    <>
                                        <div className="info-row">
                                            <div className="info-column header">
                                                {this.props.t("amount")}
                                            </div>
                                            <div className="info-column">
                                                {animal.AnmCnt || "-"}
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    this.props.animal.AnimalKind === AnimalTypes.PIGLET &&
                                    <>
                                        <div className="info-row">
                                            <div className="info-column header">
                                                {this.props.t("amount")}
                                            </div>
                                            <div className="info-column">
                                                {animal.AnmCnt || "-"}
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className="info-column header">
                                                {this.props.t("animalDocuments.averageBirthWeight")}
                                            </div>
                                            <div className="info-column">
                                                {this.getAverageWeightOfBirth()}
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className="info-column header">
                                                {this.props.t("animalDocuments.averageSeparationWeight")}
                                            </div>
                                            <div className="info-column">
                                                {this.getAverageWeightOfSeparation()}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {this.props.t("supplier")}
                                    </div>
                                    <div className={"info-column"}>
                                        {this.getSupplier()}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {this.props.t("race")}
                                    </div>
                                    <div className="info-column">
                                        {this.getRace()}
                                    </div>
                                </div>
                                {
                                    this.props.animal.AnimalKind === AnimalTypes.SOW &&
                                    <>
                                        <div className="info-row">
                                            <div className={"info-column header"}>
                                                {this.props.t("animalDocuments.activeNipples")}
                                            </div>
                                            <div className="info-column">
                                                {this.getLastActiveNipples()}
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className={"info-column header"}>
                                                {this.props.t("animalDocuments.sowPigletsCount")}
                                            </div>
                                            <div className="info-column">
                                                {
                                                    this.getCurrentPigletsForASow()
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </Col>
                            <Col md={6}>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {this.props.t("birthDate")}
                                    </div>
                                    <div className="info-column">
                                        {animal.DtaBrthTime ? this.getBirth(animal.DtaBrthTime) : "-"}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {this.props.t("insertionDate")}
                                    </div>
                                    <div className="info-column">
                                        {animal.DtaInTime ? moment(animal.DtaInTime).format("DD.MM.YYYY") : "-"}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {dthReason.type === 1 ? this.props.t("sellDate") : this.props.t("animalDocuments.deathDate")}
                                    </div>
                                    <div className="info-column">
                                        {animal.DtaDthTime ? moment(animal.DtaDthTime).format("DD.MM.YY") : "-"}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {dthReason.type === 1 ? this.props.t("animalDocuments.soldTo") : this.props.t("animalDocuments.deathReason")}
                                    </div>
                                    <div className={"info-column"}>
                                        {dthReason.value}
                                    </div>
                                </div>
                                {
                                    this.props.animal.AnimalKind === AnimalTypes.SOW &&
                                    <div className="info-row">
                                        <div className={"info-column header"}>
                                            {this.props.t("animalDocuments.plannedBirth")}
                                        </div>
                                        <div className="info-column">
                                            {getPlannedParturitionForASow(cycleTable)}
                                        </div>
                                    </div>
                                }
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {this.props.t("placement")}
                                    </div>
                                    <div className="info-column">
                                        {this.getLocation() || "-"}
                                    </div>
                                </div>
                                {
                                    this.props.animal.AnimalKind === AnimalTypes.PIGLET &&
                                    <>
                                        <div className="info-row">
                                            <div className="info-column header">
                                                {this.props.t("animalDocuments.lastSeparationDate")}
                                            </div>
                                            <div className="info-column">
                                                {this.getLastDateOfSeparation()}
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className="info-column header">
                                                {this.props.t("animalDocuments.predictedSaleDate")}
                                            </div>
                                            <div className="info-column">
                                                {this.getExpectedDateOfSale()}
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {this.props.t("technologyGroup")}
                                    </div>
                                    <div className={"info-column"}>
                                        {(technologyGroup ? moment(technologyGroup.StartTime).format("DD-MM-YYYY") : "-")}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
            </Card>
        )
    }
}

BasicInfoBox.propTypes = {
    showEdit: PropTypes.bool
};

BasicInfoBox.defaultProps = {
    showEdit: true
};

BasicInfoBox = connect(
    state => ({
        farm: state.location.farm,
        animal: state.animalDocuments.selectedItem,
        events: state.animalDocuments.events,
        races: state.dictionary.race.WData[state.language.locale],
        suppliers: state.dictionary.suppliers.WData[state.language.locale],
        fallReasons: state.dictionary.fallReasons.WData[state.language.locale],
        clients: state.dictionary.clients.WData[state.language.locale],
        cycleTable: state.animalDocuments.cycleTable
    })
)(BasicInfoBox);

BasicInfoBox = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(BasicInfoBox);

export default withTranslation()(BasicInfoBox);
